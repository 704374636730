import { RefObject, useEffect, useRef, useState } from 'react';

// for scalling dispaly, it wont be an exact 0, so +-1 margin is being used here.
const isReachingBottom = ({ scrollTop, clientHeight, scrollHeight }: HTMLDivElement) =>
  Math.abs(scrollTop + clientHeight - scrollHeight) < 1;

export const useScroll = (
  containerRef?: RefObject<HTMLDivElement>
): {
  nodeRef: RefObject<HTMLDivElement>;
  scrollHeight: number | undefined;
  wasBottom: boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
} => {
  const localRef = useRef<HTMLDivElement>(null);
  const nodeRef = containerRef || localRef;
  const [wasBottom, setWasBottom] = useState(false);

  useEffect(() => {
    if (!nodeRef.current) return;
    const ftn = (e: Event) => {
      const target = e.target as HTMLDivElement;

      setWasBottom(isReachingBottom(target));
    };

    nodeRef.current.addEventListener('scroll', ftn);

    //assume it's at bottom already, if there is no scroll atm
    if (nodeRef.current.clientHeight === nodeRef.current.scrollHeight) setWasBottom(true);

    return () => {
      if (nodeRef.current) nodeRef.current.removeEventListener('scroll', ftn);
    };
  }, [nodeRef.current]);

  const scrollToTop = () => {
    if (!nodeRef.current || !nodeRef.current.scrollTo) return;

    nodeRef.current.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const scrollToBottom = () => {
    if (!nodeRef.current || !nodeRef.current.scrollTo) return;

    nodeRef.current.scrollTo({
      top: nodeRef.current.scrollHeight - nodeRef.current.clientHeight,
      behavior: 'auto',
    });

    setWasBottom(true);
  };

  return {
    nodeRef,
    scrollHeight: nodeRef.current ? nodeRef.current.scrollHeight : 0,
    wasBottom,
    scrollToTop,
    scrollToBottom,
  };
};
