import React from 'react';
import { usePusher } from '../hooks';
import { EventData } from '../interfaces';

export const PusherListener: React.FC<{
  channels: string[];
  events: EventData[];
  channelsDeps: unknown[];
  eventsDeps: unknown[];
}> = ({ channels, events, channelsDeps = [], eventsDeps = [] }) => {
  const { useConnection, useSubscribe, useEvents } = usePusher();

  useConnection();
  useSubscribe(channels, channelsDeps);
  useEvents(events, eventsDeps);

  return null;
};
