import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import _omit from 'lodash/omit';
import { INIT_STATE, STORE_KEY, EMessageType, ConversationState } from '../interfaces';
import { ConversationService } from '../services';
import { ICreateConversationPayload } from '../services/interfaces';
import { Publisher } from '../interfaces/publisher';

const createConversation = createAsyncThunk(
  `${STORE_KEY}/createConversation`,
  async ({ campaignId, payload }: { campaignId: number; payload: ICreateConversationPayload}) => {
    const { data } = await ConversationService.createConversation(campaignId, payload);

    return data;
  }
);

const getConversation = createAsyncThunk(
  `${STORE_KEY}/getConversation`,
  async ({ campaignId, conversationId }: { campaignId: number; conversationId: string }) => {
    const { data } = await ConversationService.getConversation(campaignId, conversationId);

    return data;
  }
);

const sendMessage = createAsyncThunk(
  `${STORE_KEY}/sendMessage`,
  async ({
    campaignId,
    conversationId,
    content,
    publisher,
  }: {
    campaignId: number;
    conversationId: string;
    content: string;
    publisher: Publisher
  }) => {
    const payload = {
      campaign_id: campaignId, 
      conversation_id: conversationId,
      content_meta: {
      template: content,
      payload: {},
    }, 
    publisher};
    const { data } = await ConversationService.sendMessage(payload);

    return data;
  }
);

const showAgentReplyingDots = createAction<ConversationState['showAgentReplyingDots']>(
  `${STORE_KEY}/showAgentReplyingDots`
);

const conversationSlice = createSlice({
  name: STORE_KEY,
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.conversation = _omit(action.payload, 'messages');
      state.messages = [...action.payload.messages];
    });
    builder.addCase(getConversation.fulfilled, (state, action) => {
      state.conversation = _omit(action.payload, 'messages');
      state.messages = [...action.payload.messages];
    });
    builder.addCase(sendMessage.pending, (state, action) => {
      const { content, conversationId } = action.meta.arg;

      //assuming the action would be successful, append a mock up message first, which would be replaced with a real one later
      state.messages = [
        ...state.messages,
        {
          content,
          content_meta: null,
          id: `${Date.now()}`,
          conversation_id: conversationId,
          type: EMessageType.RECEIVED,
          created_at: new Date().toJSON(),
          updated_at: new Date().toJSON(),
        },
      ];
    });
    builder.addCase(showAgentReplyingDots, (state, action) => {
      state.showAgentReplyingDots = action.payload;
    });
  },
});

export { conversationSlice };
export default conversationSlice.reducer;

export { createConversation, getConversation, sendMessage, showAgentReplyingDots };
