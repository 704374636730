import { SettingsState } from './';

export const STORE_KEY = 'settings';
export const FETCHING_STATUS = Symbol('fetchingStatus');

export enum NewMessagesNotificationSettings {
  POPUP = 'popup',
  BADGE = 'badge',
}

export const INIT_STATE: SettingsState = {
  office_hours: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  },
  time_zone: 'Asia/Taipei',
  new_messages_notification: NewMessagesNotificationSettings.BADGE,
  auto_popup: {
    first: [],
    next: [],
    minimize: [],
  },
  [FETCHING_STATUS]: true,
};
