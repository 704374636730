import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConversationContainer, ConversationListener } from '@onr/conversation';
import { Launcher, logger, Popup, showPopup, useAutoPopupEffect } from '@onr/core';
import { useRemoteSettingsEffect } from '@onr/settings';
import { PusherProvider } from '@onr/pusher';
import { getPersistStore } from './store';
import { WebChatConfigs, STORE_KEY } from './slice';
import { useConversationEffect, useImpressionCountEffect } from './hooks';
import { selectFetchingSettingStatus } from './app-selectors';
import '../assets/styles.less';

// To contian effect within a component without re-rendering parent component
const EffectComponent: React.FC = (): null => {
  useConversationEffect();
  useAutoPopupEffect();

  return null;
};

export const AppCore: React.FC<WebChatConfigs> = props => {
  const { campaignId, embeddedWrapperSelectorId, settings } = props;
  if (!campaignId || campaignId <= 0) {
    return null;
  }

  // TODO: should move this to higher level
  const launcherIconUrl = settings?.launcher_icon_url;
  const isEmbedded = !!embeddedWrapperSelectorId;
  const isSettingFetching = useSelector(selectFetchingSettingStatus);
  const dispatch = useDispatch();
  logger.setCampaignId(campaignId);

  useImpressionCountEffect();
  useRemoteSettingsEffect(campaignId);

  if (isSettingFetching) {
    return <div className="contact-loop-web-chat"></div>;
  }

  if (isEmbedded) {
    dispatch(showPopup());

    return (
      <div className="contact-loop-web-chat is-embedded">
        <EffectComponent />
        <Popup configs={props}>
          <ConversationContainer launcherIconUrl={launcherIconUrl} />
        </Popup>
        <ConversationListener />
      </div>
    );
  }

  return (
    <div className="contact-loop-web-chat">
      <EffectComponent />
      <Launcher className="conversation-launcher" launcherIconUrl={launcherIconUrl} />
      <Popup configs={props}>
        <ConversationContainer launcherIconUrl={launcherIconUrl} />
      </Popup>
      <ConversationListener />
    </div>
  );
};

export const App: React.FC<WebChatConfigs> = props => {
  const { store, persistor } = getPersistStore({ preloadedState: { [STORE_KEY]: props } });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PusherProvider>
          <AppCore {...props} />
        </PusherProvider>
      </PersistGate>
    </Provider>
  );
};
