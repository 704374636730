import React from 'react';
import { render } from 'react-dom';
import { App } from '@app';
import { WebChatAppInterface, WebChatConfigs } from '@app/slice';

// window.addEventListener(
//   'load',
//   () => {
//     const container = document.createElement('div');
//     container.id = 'onr-ava-webchat';
//     document.body.appendChild(container);

//     const script = document.querySelector('#AVA_WEBCHAT')!;
//     const campaignId = script.getAttribute('data-campaign-id') || '0';
//     const title = script.getAttribute('data-title') || 'AVA Web Chat';

//     //TODO: maybe check if valid or not
//     setTimeout(() => {
//       render(<App campaignId={parseInt(campaignId)} title={title} />, container);
//     });
//   },
//   false
// );

const webchat = {
  init: (campaignId: number, appProps: WebChatAppInterface): void => {
    const { title, settings, embedded_wrapper_selector_id: embeddedWrapperSelectorId } = appProps;

    if (campaignId <= 0 || !title) {
      return;
    }

    const configs: WebChatConfigs = {
      campaignId,
      title,
      settings,
      embeddedWrapperSelectorId,
    };

    const container = document.createElement('div');
    container.id = 'onr-ava-webchat';

    if (embeddedWrapperSelectorId) {
      container.className = 'is-embedded';

      let customContainer = document.getElementById(embeddedWrapperSelectorId);
      if (!customContainer) {
        customContainer = document.createElement('div');
        customContainer.id = embeddedWrapperSelectorId;
        document.body.appendChild(customContainer);
      }
      customContainer.appendChild(container);
    } else {
      document.body.appendChild(container);
    }

    setTimeout(() => {
      render(<App {...configs} />, container);
    });
  },
};

setTimeout(() => {});

export default webchat;
