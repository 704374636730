import { useEffect, useRef, createContext, useContext, MutableRefObject } from 'react';
import Pusher from 'pusher-js';
import type * as PusherTypes from 'pusher-js';
import _isArray from 'lodash/isArray';
import { EventData, StateChangeData } from '../interfaces';

export const PusherRefContext = createContext<MutableRefObject<Pusher | null> | null>(null);

export const usePusherRefContext = (): MutableRefObject<Pusher | null> | null =>
  useContext(PusherRefContext);

export const usePusher = (
  key = process.env.PUSHER_KEY,
  cluster = process.env.PUSHER_CLUSTER
): {
  pusherRef: MutableRefObject<Pusher | null> | null;
  useConnection: () => void;
  useSubscribe: (channels: string | string[], deps: unknown[]) => void;
  useEvents: (events: EventData | EventData[], deps: unknown[]) => void;
} => {
  if (!key || !cluster) {
    throw new Error(
      'You should either pass PUSHER_KEY and PUSHER_CLUSTER or include them in .env!'
    );
  }

  // with this pattern, it allows a single pusher connection mounted at the root node
  // or component-wise, on-demand, connection
  const contextRef = usePusherRefContext();
  const localRef = useRef<PusherTypes.default | null>(null);
  const pusherRef = contextRef || localRef;

  const useConnection = () => {
    useEffect(() => {
      //only creating a new pusher connection if pusherRef is a local one.
      if (pusherRef === localRef) {
        pusherRef.current = new Pusher(key, { cluster });

        pusherRef.current?.connection.bind('state_change', (state: StateChangeData) => {
          console.debug('[pusher]: state changed', state);
          if (state.current === 'connected') {
            console.debug('[pusher]: Connection to Pusher establed.');
            return;
          }
          if (state.current === 'disconnected') {
            //The Channels connection was previously connected and has now intentionally been closed.
            //There's no point in showing warning msg, if it is done intentionally.
            return;
          }
          console.debug('[pusher]: Connection to Pusher is lost');
        });
      }

      return () => {
        //only disconnect the pusher if pusherRef is a local one
        if (pusherRef === localRef) {
          console.debug('[pusher]: disconnecting');

          pusherRef.current?.disconnect();
        }
      };
    }, []);
  };

  const useSubscribe = (_channels: string | string[], deps: unknown[]) => {
    const channels = _isArray(_channels) ? _channels : [_channels];

    useEffect(() => {
      //put it to the end of callstack, to allow potential PusherProvider to connect first
      //as useEffect is running with bottom-top approach
      //2020-11-23 Raymond
      setTimeout(() => {
        channels.forEach(channel => {
          console.debug('[pusher]: subscribe ', channel);
          pusherRef.current?.subscribe(channel);
        });
      });

      return () => {
        channels.forEach(channel => {
          console.debug('[pusher]: unsubscribe ', channel);
          pusherRef.current?.unsubscribe(channel);
        });
      };
    }, deps);
  };

  const useEvents = (_events: EventData | EventData[], deps: unknown[]) => {
    const events = _isArray(_events) ? _events : [_events];

    useEffect(() => {
      //put it to the end of callstack, to allow potential PusherProvider to connect first
      //as useEffect is running with bottom-top approach
      //2020-11-23 Raymond
      setTimeout(() => {
        events.forEach(({ channel, event, handler }) => {
          console.debug(
            `[pusher]: listenting to channel ${channel} event ${event}`,
            pusherRef.current?.channel(channel),
            pusherRef.current?.allChannels()
          );
          if (pusherRef.current?.channel(channel))
            pusherRef.current?.channel(channel).bind(event, handler);
        });
      });

      return () => {
        events.forEach(({ channel, event, handler }) => {
          console.debug(
            `[pusher]: leaving channel ${channel} event ${event}`,
            pusherRef.current?.channel(channel),
            pusherRef.current?.allChannels()
          );
          if (pusherRef.current?.channel(channel))
            pusherRef.current?.channel(channel).unbind(event, handler);
        });
      };
    }, deps);
  };

  return {
    pusherRef,
    useConnection,
    useSubscribe,
    useEvents,
  };
};
