import React from 'react';
import { usePusher, PusherRefContext } from '../hooks';

export const PusherProvider: React.FC = ({ children }) => {
  const { pusherRef, useConnection } = usePusher();

  useConnection();

  return <PusherRefContext.Provider value={pusherRef}>{children}</PusherRefContext.Provider>;
};
