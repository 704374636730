import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { OfficeHoursSettings } from '../interfaces';

export const isWithinOfficeHours = (
  officeHours: OfficeHoursSettings,
  timeZone: string
): boolean => {
  const date = timeZone ? utcToZonedTime(new Date(), timeZone) : new Date();
  const day = date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6;
  const time = date.getHours() * 100 + date.getMinutes();
  const [start, end] = officeHours[day] || [];

  if (start !== undefined && end != undefined) {
    return start < time && time < end;
  }

  return false;
};
