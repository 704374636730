import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsPopupShown } from '@onr/core';
import { getConversation, selectConversation } from '@onr/conversation';
import { selectCampaignId } from '../selectors';

/**
 * Application-wise Side Effect accross multiple modules
 *
 * 1. refetch conversation whenever isPopupShown or badgeCount changes and isPopupShown is truthy
 *
 */

export const useConversationEffect = (): void => {
  const campaignId = useSelector(selectCampaignId);
  const conversation = useSelector(selectConversation);
  const isPopupShown = useSelector(selectIsPopupShown);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPopupShown && conversation?.id) {
      dispatch(getConversation({ campaignId, conversationId: conversation.id }));
    }
  }, [isPopupShown]);

  useEffect(() => {
    if (conversation?.id) {
      localStorage.setItem(
        'contactloop-messenger',
        JSON.stringify({
          conversation: {
            id: conversation.id,
            lead_id: conversation.lead_id,
          },
        })
      );
    }
  }, [conversation?.id]);
};
