import { Http, logCatch, logInfo } from '@onr/core/utils';
import { IIncreaseWebChatImpressionsCountPayload } from './interfaces';

export const WebChatService = {
  increaseImpressionsCount: async (
    campaign_id: number,
    payload: IIncreaseWebChatImpressionsCountPayload
  ): Promise<any> => {
    try {
      const res = await Http.put(
        `/api/campaigns/${campaign_id}/webchat_stats/impressions`,
        payload
      );
      logInfo('WebChatService', 'increaseImpressionsCount', payload);

      return res;
    } catch (e) {
      throw logCatch(e, 'WebChatService', 'increaseImpressionsCount');
    }
  },
};
