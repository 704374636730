import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaignId } from '../selectors';
import { WebChatService } from '@onr/conversation';

export const useImpressionCountEffect = (): void => {
  const campaignId = useSelector(selectCampaignId);

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    const payload = {
      count: 1,
    };

    WebChatService.increaseImpressionsCount(campaignId, payload);
  }, [campaignId]);
};
