import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValuesType } from 'utility-types';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { AutoPopupSettings, TIME_UNITS_IN_MILLISECONDS } from '@app/interfaces';
import { selectAutoPopupConditions } from '@app/app-selectors';
import { togglePopup } from '../redux';

const isEmpty = (values: ValuesType<AutoPopupSettings>): values is [] => {
  return values.length === 0;
};

const isZero = (values: ValuesType<AutoPopupSettings>): values is [0] => {
  return values.length === 1 && values[0] === 0;
};

const printLog = (type: string, msg: string): void => {
  console.log(`[Autopopup/${type}] ${msg}`);
};

export const useAutoPopupEffect = (): void => {
  const dispatch = useDispatch();
  const {
    autoPopup,
    isLauncherShown,
    isPopupShown,
    isPopupHovered,
    isPopupFocused,
    lastInteractionTime,
  } = useSelector(selectAutoPopupConditions);
  const { first, next, minimize } = autoPopup;

  useEffect(() => {
    // if not interacted, ie. first time
    if (!isLauncherShown || isEmpty(first) || lastInteractionTime) return;

    if (isZero(first)) {
      printLog('first', 'popuping');
      dispatch(togglePopup(true));

      return;
    } else {
      const [value, units] = first;
      const timerID = setTimeout(() => {
        printLog('first', 'popuping');
        dispatch(togglePopup(true));
      }, value * TIME_UNITS_IN_MILLISECONDS[units]);

      printLog('first', 'timeout set');

      return () => {
        printLog('first', 'timeout cleared');
        clearTimeout(timerID);
      };
    }
  }, [first, lastInteractionTime, isLauncherShown]);

  useEffect(() => {
    // only run once after config fetched
    if (isZero(next)) {
      printLog('next', 'popuping');

      dispatch(togglePopup(true));
    }
  }, [isLauncherShown, next]);

  useEffect(() => {
    // if interacted (ie. not first time) and is not showing popup
    if (!isLauncherShown || !lastInteractionTime || isEmpty(next) || isZero(next) || isPopupShown)
      return;

    const diff = differenceInMilliseconds(new Date(), new Date(lastInteractionTime));
    const [value, units] = next;
    const valueInMilliseconds = value * TIME_UNITS_IN_MILLISECONDS[units];
    const timerID = setTimeout(
      () => {
        printLog('next', 'popuping');

        dispatch(togglePopup(true));
      },
      diff >= valueInMilliseconds ? 0 : valueInMilliseconds - diff
    );

    printLog('next', 'timeout set');

    return () => {
      console.log('next', 'timeout cleared');
      clearTimeout(timerID);
    };
  }, [next, lastInteractionTime, isPopupShown, isLauncherShown]);

  //minimize effect
  useEffect(() => {
    // value 0 refers to manually closed
    if (
      !isLauncherShown ||
      isPopupHovered ||
      isPopupFocused ||
      isEmpty(minimize) ||
      isZero(minimize)
    )
      return;

    // should only minimize if popup has been interacted before and the popup is still shown
    if (isPopupShown) {
      const [value, units] = minimize;
      const timerID = setTimeout(() => {
        printLog('minimize', 'minimized');
        dispatch(togglePopup(false));
      }, value * TIME_UNITS_IN_MILLISECONDS[units]);

      printLog('minimize', 'timeout set');

      return () => {
        printLog('minimize', 'timeout cleared');
        clearTimeout(timerID);
      };
    }
  }, [
    minimize,
    lastInteractionTime,
    isPopupHovered,
    isPopupFocused,
    isPopupShown,
    isLauncherShown,
  ]);
};

differenceInMilliseconds;
