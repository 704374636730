import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistConfig } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/session';
import storageLocal from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { Optional } from 'utility-types';
import { coreSlice, STORE_KEY as CORE_STORE_KEY, CoreState } from '@onr/core';
import { settingsSlice, STORE_KEY as SETTINGS_STORE_KEY, SettingsState } from '@onr/settings';
import {
  conversationSlice,
  STORE_KEY as CONVERSATION_STORE_KEY,
  ConversationState,
} from '@onr/conversation';
import { appSlice, STORE_KEY as APP_STORE_KEY, AppState, STORE_KEY } from './slice';

export interface State {
  [APP_STORE_KEY]: AppState;
  [CORE_STORE_KEY]: CoreState;
  [SETTINGS_STORE_KEY]: SettingsState;
  [CONVERSATION_STORE_KEY]: ConversationState;
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageLocal,
  stateReconciler: function (
    inboundState: State,
    originalState: State,
    reducedState: State,
    config: PersistConfig<State>
  ): State {
    //we are giving up persisted state if the campaign id is different
    if (originalState[STORE_KEY].campaignId !== inboundState[STORE_KEY].campaignId)
      return originalState;

    return autoMergeLevel2(inboundState, originalState, reducedState, config);
  },
};

const persistedReducer = persistReducer<State>(
  persistConfig,
  combineReducers({
    [APP_STORE_KEY]: appSlice.reducer,
    [CORE_STORE_KEY]: coreSlice.reducer,
    [SETTINGS_STORE_KEY]: settingsSlice.reducer,
    [CONVERSATION_STORE_KEY]: conversationSlice.reducer,
  })
);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   }),
// });

export const getStore = (
  config: Optional<Parameters<typeof configureStore>[0]>
): ReturnType<typeof configureStore> =>
  configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    ...config,
  });

export const getPersistStore = (
  config: Optional<Parameters<typeof configureStore>[0]>
): { store: ReturnType<typeof configureStore>; persistor: ReturnType<typeof persistStore> } => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    ...config,
  });

  return {
    store,
    persistor: persistStore(store),
  };
};

// export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
