import React from 'react';
import styled from 'styled-components';

const JumpingDotsStyled = styled.div`
  .dot {
    position: relative;
    animation: jump 1s infinite;
    display: inline-block;
    background-color: steelblue;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .dot-1 {
    animation-delay: 200ms;
  }

  .dot-2 {
    animation-delay: 400ms;
  }

  .dot-3 {
    animation-delay: 600ms;
  }

  @keyframes jump {
    0% {
      bottom: 0px;
    }
    20% {
      bottom: 5px;
    }
    40% {
      bottom: 0px;
    }
  }
`;

export const JumpingDots: React.FC = () => {
  return (
    <JumpingDotsStyled>
      <span className="dot dot-1"></span>
      <span className="dot dot-2"></span>
      <span className="dot dot-3"></span>
    </JumpingDotsStyled>
  );
};
