import React from 'react';
import format from 'date-fns/format';
import { Link as LinkIcon } from 'react-feather';
import { ValuesType } from 'utility-types';
import { ContentMeta, MessageData } from '../interfaces';

export const Message: React.FC<MessageData> = ({ type, content, content_meta, created_at }) => (
  <div className={`message-wrapper ${type}`}>
    <div className="message-body shadow-sm">
      <p
        color="inherit"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }}
      >
        <Content content={content} contentMeta={content_meta} />
      </p>
    </div>
    <p className="message-datetime">{format(new Date(created_at), 'MM/dd HH:mm')}</p>
  </div>
);

interface ContentProps {
  content: string;
  contentMeta: null | ContentMeta;
}

const parseContentNode = (payload: ValuesType<ContentMeta['payload']>) => {
  const { type, data } = payload;
  const { text } = data;

  switch (type) {
    case 'link':
    case 'upload_link':
    case 'download_file':
      return (
        <a href={text} target="_blank" rel="noreferrer" className="underline text-blue-500">
          <LinkIcon size={14} color="rgba(75, 85, 99)" style={{ marginRight: '0.5em' }} />
          {text.slice(0, 30)}...
        </a>
      );

    default:
      return text;
  }
};

//parse template with O(n)
const parse = (template: string, payload: ContentMeta['payload']) => {
  const res = [];
  let temp = '';
  let isOpening = false;

  for (let i = 0; i < template.length; i++) {
    const c = template[i];
    if (c === '{') {
      if (!isOpening && temp) res.push(temp);

      isOpening = true;
      temp = '';

      continue;
    }
    if (c === '}') {
      if (isOpening && payload[temp]) {
        res.push(parseContentNode(payload[temp]));
      }
      isOpening = false;
      temp = '';

      continue;
    }

    temp += c;
  }

  res.push(temp);

  return res;
};

const Content: React.FC<ContentProps> = ({ content, contentMeta }) => {
  if (contentMeta) {
    const { template, payload } = contentMeta;

    return <>{parse(template, payload)}</>;
  } else return <>{content}</>;
};
