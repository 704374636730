import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from '@app/app-actions';
import { fetchSettings, selectSettings } from '../redux';
import { isWithinOfficeHours } from '../utils';
import { NewMessagesNotificationSettings } from '../interfaces';

export const useRemoteSettingsEffect = (campaignId: number): void => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);

  useEffect(() => {
    dispatch(fetchSettings(campaignId));
  }, []);

  useEffect(() => {
    const {
      office_hours: officeHours,
      time_zone: timeZone,
      new_messages_notification: newMessagesNotification,
    } = settings;

    dispatch(
      AppActions.remoteConfigFetched({
        isWithinOfficeHours: isWithinOfficeHours(officeHours, timeZone),
        shouldPopupOnNewMessage: newMessagesNotification === NewMessagesNotificationSettings.POPUP,
      })
    );
  }, [settings]);
};
