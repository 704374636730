import { State, STORE_KEY, OfficeHoursSettings, SettingsState } from '../interfaces';

export const selectOfficeHoursSettings = (state: State): OfficeHoursSettings =>
  state[STORE_KEY].office_hours;

export const selectTimeZoneSettings = (state: State): SettingsState['time_zone'] =>
  state[STORE_KEY].time_zone;

export const selectNewMessagesNotificationSettings = (
  state: State
): SettingsState['new_messages_notification'] => state[STORE_KEY].new_messages_notification;

export const selectSettings = (state: State): SettingsState => state[STORE_KEY];
