import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import { selectBadgeCount, selectIsBadgeShown } from '../redux';

const CustomBadge: React.FC = ({ children }) => {
  const isBadgeShown = useSelector(selectIsBadgeShown);
  const badgeCount = useSelector(selectBadgeCount);

  //todo: to show new incoming messages count
  return isBadgeShown ? <Badge count={badgeCount}>{children}</Badge> : <>{children}</>;
};

export { CustomBadge as Badge };
