import { Http, logCatch, logInfo } from '@onr/core/utils';
import { ConversationData, MessageData } from '../interfaces';
import { ICreateConversationPayload, ISendMessagePayload } from './interfaces';

export const ConversationService = {
  createConversation: async (campaign_id: number, payload: ICreateConversationPayload) => {
    try {
      const res = await Http.post<ConversationData>(
        `/api/campaigns/${campaign_id}/conversations`,
        payload
      );
      logInfo('ConversationService', 'createConversation', payload);

      return res;
    } catch (e) {
      throw logCatch(e, 'ConversationService', 'createConversation');
    }
  },
  getConversation: async (campaign_id: number, conversation_id: string) => {
    try {
      const res = await Http.get<ConversationData>(
        `/api/campaigns/${campaign_id}/conversations/${conversation_id}`
      );
      logInfo('ConversationService', 'getConversation', { conversation_id });

      return res;
    } catch (e) {
      throw logCatch(e, 'ConversationService', 'getConversation');
    }
  },
  sendMessage: async (payload: ISendMessagePayload) => {
    try {
      const { campaign_id, conversation_id } = payload;
      const res = await Http.post<MessageData>(
        `/api/campaigns/${campaign_id}/conversations/${conversation_id}/messages`,
        payload
      );
      logInfo('ConversationService', 'sendMessage', { conversation_id });

      return res;
    } catch (e) {
      throw logCatch(e, 'ConversationService', 'sendMessage');
    }
  },
} as const;
