import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input, Button } from 'antd';
import { Send as SendIcon } from 'react-feather';
import { sendMessage, selectAgentReplyingDots, showAgentReplyingDots } from '../redux';

const PLACEHOLDER = 'Type something....';

const StyledDiv = styled.div`
  .contact-loop-web-chat & {
    textarea {
      resize: none;
      overflow: hidden;
      width: calc(100% - 40px);
      height: 100%;
      background: transparent;
    }

    textarea:focus {
      box-shadow: none;
    }
  }
`;

const StyledButton = styled(Button)`
  .contact-loop-web-chat & {
    top: -5px;
  }
`;

const Footer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { campaignId: number; conversationId: string }
> = ({ campaignId, conversationId, className }) => {
  const container = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const isShowJumpingDots = useSelector(selectAgentReplyingDots);

  const [textarea, setTextarea] = useState('');

  const sendMsg = () => {
    if (!textarea || !textarea.trim()) return;
    
    const publisher = {
      'domain': window.location.hostname,
      'incoming_message_url': window.location.href
    }
    dispatch(
      sendMessage({
        campaignId,
        conversationId,
        content: textarea.trim(),
        publisher,
      })
    );

    setTextarea('');

    // A fake agent replying animation
    setTimeout(() => {
      dispatch(showAgentReplyingDots(true));
    }, 2000);

    setTimeout(() => {
      if (isShowJumpingDots) {
        dispatch(showAgentReplyingDots(false));
      }
    }, 20000);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey) {
      return;
    }
    e.preventDefault();

    sendMsg();
  };

  return (
    <StyledDiv ref={container} className={className}>
      <Input.TextArea
        size="large"
        bordered={false}
        autoSize={{ minRows: 1, maxRows: 1 }}
        placeholder={PLACEHOLDER}
        onPressEnter={onPressEnter}
        value={textarea}
        onChange={e => setTextarea(e.target.value)}
      />
      <StyledButton type="link" size="middle" icon={<SendIcon />} onClick={sendMsg} />
    </StyledDiv>
  );
};

const MemoFooter = memo(Footer);

export { MemoFooter as Footer };
