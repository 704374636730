import { createSlice } from '@reduxjs/toolkit';
import { SettingsState, INIT_STATE as INIT_SETTINGS_STATE } from '@onr/settings';

export const STORE_KEY = 'app';

interface StaticSettings extends SettingsState {
  launcher_icon_url?: string; // TODO: should move to configs
}

export interface WebChatAppInterface {
  title?: string;
  settings?: StaticSettings;
  embedded_wrapper_selector_id?: string;
}

export interface WebChatConfigs {
  campaignId: number;
  title: string;
  settings?: StaticSettings;
  embeddedWrapperSelectorId?: string;
}

export interface AppState extends WebChatConfigs {}

export interface State {
  [STORE_KEY]: AppState;
}

export const INIT_STATE: AppState = {
  campaignId: -1,
  title: '',
  settings: INIT_SETTINGS_STATE,
};

export const appSlice = createSlice({
  name: STORE_KEY,
  initialState: INIT_STATE,
  reducers: {},
});

export default appSlice.reducer;
