import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';
import { MessageCircle as MessageCircleIcon } from 'react-feather';
import { togglePopup, selectIsLauncherShown } from '../redux';
import { Badge } from './';

const StyledDiv = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;

  .ant-btn.ant-btn-lg {
    height: 60px;
    width: 60px;
  }
`;

interface Props {
  className: string;
  launcherIconUrl?: string;
}

export const Launcher: React.FC<Props> = ({ className, launcherIconUrl }) => {
  const dispatch = useDispatch();
  const isLauncherShown = useSelector(selectIsLauncherShown);

  if (!isLauncherShown) return null;

  return (
    <StyledDiv className={className}>
      <Badge>
        {Boolean(launcherIconUrl) ? (
          <div
            className="launcher-icon"
            style={{ width: 100, height: 100, cursor: 'pointer' }}
            onClick={() => dispatch(togglePopup())}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={launcherIconUrl}
              alt="launcher icon"
            />
          </div>
        ) : (
          <Button
            size="large"
            type="primary"
            shape="circle"
            icon={<MessageCircleIcon size={36} />}
            onClick={() => dispatch(togglePopup())}
          />
        )}
      </Badge>
    </StyledDiv>
  );
};
