import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { INIT_STATE, STORE_KEY, FETCHING_STATUS } from '../interfaces';
import { SettingsService } from '../services';

const fetchSettings = createAsyncThunk(
  `${STORE_KEY}/fetchRemoteSettings`,
  async (campaignId: number) => {
    const { data } = await SettingsService.getSettings(campaignId);

    return data;
  }
);
const settingsSlice = createSlice({
  name: STORE_KEY,
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      // FETCHING_STATUE is symbol, and would not show on devtool.
      state[FETCHING_STATUS] = false;

      for (const setting of action.payload) {
        try {
          Object.assign(state, { [setting.name]: JSON.parse(setting.value) });
        } catch (e) {
          //TODO: should log error
        }
      }
    });
  },
});

export { settingsSlice };
export { fetchSettings };
export default settingsSlice.reducer;
