const { LogglyClient } = require('universal-loggly');
import { detect } from 'detect-browser';

type LogType = 'error' | 'info' | 'warn' | 'debug';

const browser = detect();
export class LogglyService {
  private static _instance: LogglyService;
  private loggly: any | null = null;
  private trackingId: string;
  private accountId: number | null = null;
  private campaignId: number | null = null;

  private constructor() {
    this.trackingId = this.generateUUID();
  }

  public static getInstance(): LogglyService {
    if (LogglyService._instance) {
      return LogglyService._instance;
    }

    LogglyService._instance = new LogglyService();

    const token: string | undefined = process.env.LOGGLY_CUSTOMER_TOKEN;
    const tag: string | undefined = process.env.LOGGLY_TAG;
    LogglyService._instance.loggly = new LogglyClient(`https://logs-01.loggly.com`, token, tag);

    return LogglyService._instance;
  }

  public setAccountId(accountId: number | null) {
    this.accountId = accountId;
  }

  public setCampaignId(campaignId: number | null) {
    this.campaignId = campaignId;
  }

  async debug(event: any, meta: any = {}) {
    console.debug(event, { ...meta });
  }

  async info(event: any, meta: any = {}) {
    await this.log('info', event, { ...meta });
  }

  async error(event: any, meta: any = {}) {
    await this.log('error', event, { ...meta });
  }

  async warn(event: any, meta: any = {}) {
    await this.log('warn', event, { ...meta });
  }

  async log(level: LogType = 'info', event: any, meta: any) {
    try {
      console[level](event, { ...meta });
      if (this.loggly) {
        const context = {
          account_id: this.accountId,
          campaign_id: this.campaignId,
          tracking_id: this.trackingId,
          browser: browser?.name,
          timestamp: new Date().getTime(),
        };

        await this.loggly[level](event, {
          ...meta,
          ...context,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  private generateUUID() {
    let d = new Date().getTime(); //Timestamp
    let d2 =
      (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });

    return uuid;
  }
}

export const logger = LogglyService.getInstance();
