import React, { RefObject, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { UserOutlined as UserOutlinedIcon } from '@ant-design/icons';
import { last } from 'lodash';
import { useScroll } from '@app/hooks';
import { initBadgeCount } from '@onr/core';
import { WHITE_LIST_MESSAGE_TYPE, EMessageType } from '../interfaces';
import { Message, JumpingDots } from '../components';
import { selectMessages, selectAgentReplyingDots } from '../redux';

const StyledDiv = styled.div`
  padding: 10px 20px;
  background-color: #f9f9f9;
  overflow-y: auto;
  position: relative;
`;

const StyledFixedReplyingAnimation = styled.div`
  position: sticky;
  bottom: 0;
  left: 10;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  launcherIconUrl?: string;
}

export const MessageContainer: React.FC<Props> = ({ className, launcherIconUrl }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const messages = useSelector(selectMessages);
  const isShowJumpingDots =
    useSelector(selectAgentReplyingDots) && last(messages)?.type === EMessageType.RECEIVED;

  return (
    <StyledDiv ref={containerRef} className={className}>
      <ScrollToBottomEffectComponent containerRef={containerRef} messages={messages} />
      {messages.map(msg =>
        WHITE_LIST_MESSAGE_TYPE.includes(msg.type) ? <Message key={msg.id} {...msg} /> : null
      )}
      {isShowJumpingDots && (
        <StyledFixedReplyingAnimation>
          <div className="message-body" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 6 }}>
              {launcherIconUrl ? (
                <img src={launcherIconUrl} alt="icon" style={{ width: '50px', height: '50px' }} />
              ) : (
                <Avatar icon={<UserOutlinedIcon />} />
              )}
            </div>
            <JumpingDots />
          </div>
        </StyledFixedReplyingAnimation>
      )}
    </StyledDiv>
  );
};

const ScrollToBottomEffectComponent: React.FC<{
  containerRef: RefObject<HTMLDivElement>;
  messages: { type: EMessageType }[];
}> = ({ containerRef, messages }) => {
  const dispatch = useDispatch();
  const { scrollToBottom, wasBottom } = useScroll(containerRef);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (wasBottom) {
      dispatch(initBadgeCount());
    }
  }, [wasBottom]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  return null;
};
