import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Spin } from 'antd';
import { Minimize2 as Minimize2Icon } from 'react-feather';
import { togglePopup, selectIsPopupShown, handlePopupFocus, handlePopupHover } from '../redux';
import { selectConversationId } from '@onr/conversation';
import { WebChatConfigs } from '@app/slice';

export interface Props {
  configs: WebChatConfigs;
}

const DEFAULT_CAPTION = 'typically reply in minutes';

const Title: React.FC<{ title: string; caption?: string }> = ({ title, caption }) => (
  <>
    <p style={{ marginBottom: 0 }}>{title}</p>
    <p
      style={{ marginBottom: 0, fontSize: '0.8rem', lineHeight: '0.6rem', color: 'lightslategrey' }}
    >
      {caption || DEFAULT_CAPTION}
    </p>
  </>
);

export const Popup: React.FC<Props> = props => {
  const { configs, children } = props;
  const { title, embeddedWrapperSelectorId } = configs;
  const isEmbedded = !!embeddedWrapperSelectorId;
  const isPopupShown = useSelector(selectIsPopupShown);
  const conversationId = useSelector(selectConversationId);
  const dispatch = useDispatch();

  if (!isPopupShown) return null;

  return (
    <div
      className={`conversation-container ${isEmbedded ? 'is-embedded' : 'is-fixed'}`}
      onFocus={() => dispatch(handlePopupFocus(true))}
      onBlur={() => dispatch(handlePopupFocus(false))}
      onMouseEnter={() => dispatch(handlePopupHover(true))}
      onMouseLeave={() => dispatch(handlePopupHover(false))}
    >
      <Card
        hoverable
        title={<Title title={title} />}
        extra={
          !isEmbedded && (
            <Button
              type="text"
              onClick={() => dispatch(togglePopup())}
              icon={<Minimize2Icon size={20} />}
            />
          )
        }
      >
        <Spin spinning={!conversationId} />
        {children}
      </Card>
    </div>
  );
};
