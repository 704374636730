import { CoreState, State, STORE_KEY } from '../interfaces';

export const selectIsLauncherShown = (state: State): CoreState['isLauncherShown'] =>
  state[STORE_KEY].isLauncherShown;

export const selectIsPopupShown = (state: State): CoreState['isPopupShown'] =>
  state[STORE_KEY].isPopupShown;

export const selectIsBadgeShown = (state: State): CoreState['isBadgeShown'] =>
  state[STORE_KEY].isBadgeShown;

export const selectBadgeCount = (state: State): CoreState['badgeCount'] =>
  state[STORE_KEY].badgeCount;
