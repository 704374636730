import { CoreState } from './redux';

export const STORE_KEY = 'core';

export const INIT_STATE: CoreState = {
  isLauncherShown: false,
  isPopupShown: false,
  isPopupHovered: false,
  isPopupFocused: false,
  isBadgeShown: true,
  badgeCount: 0,
  shouldPopupOnNewMessage: false,
  lastInteractionTime: undefined,
};
