import { selectCampaignId } from '@app/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createConversation, selectConversationId } from '../redux';
import { MessageContainer, Footer } from './';

const DivStyled = styled.div`
  height: 100%;
`;

const MessageContainerStyled = styled(MessageContainer)`
  height: calc(100% - 40px);
`;

const FooterStyled = styled(Footer)`
  height: 40px;
`;

interface Props {
  launcherIconUrl?: string;
}

export const ConversationContainer: React.FC<Props> = ({ launcherIconUrl }) => {
  const dispatch = useDispatch();
  const campaignId = useSelector(selectCampaignId);
  const conversationId = useSelector(selectConversationId);

  useEffect(() => {
    if (!conversationId) {
      const publisher = {
        domain: window.location.hostname,
        conversation_created_url: window.location.href,
      };
      const payload = {
        publisher,
      };
      dispatch(createConversation({ campaignId, payload }));
    }
  }, []);

  return (
    <DivStyled>
      <MessageContainerStyled launcherIconUrl={launcherIconUrl} />
      <FooterStyled campaignId={campaignId} conversationId={conversationId} />
    </DivStyled>
  );
};
