import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { last } from 'lodash';
import * as AppActions from '@app/app-actions';
import { PusherListener } from '@onr/pusher';
import { selectConversation, selectMessages, getConversation } from '../redux';
import { showAgentReplyingDots } from '../redux';
import { EMessageType } from '../interfaces';

export const ConversationListener: React.FC = () => {
  const dispatch = useDispatch();
  const conversation = useSelector(selectConversation);
  const messages = useSelector(selectMessages);
  const channelName = `webchat_conversation_${conversation.id}_replied`;
  const channels = [channelName];
  const channelsDeps = [conversation.id];
  const hideAgentReplyingDots = async () => dispatch(showAgentReplyingDots(false));
  const getConversationPromise = async () =>
    dispatch(
      getConversation({ campaignId: conversation.campaign_id, conversationId: conversation.id })
    );
  const events = [
    {
      channel: channelName,
      event: 'App\\Events\\WebchatReplied',
      handler: async () => {
        dispatch(
          AppActions.receiveMessageReplied({
            campaignId: conversation.campaign_id,
            conversationId: conversation.id,
          })
        );

        await getConversationPromise();

        if (last(messages)?.type === EMessageType.SENT) {
          await hideAgentReplyingDots();
        }
      },
    },
  ];
  const eventsDeps = [conversation.id, conversation.journey_id];

  return (
    <PusherListener
      channels={channels}
      channelsDeps={channelsDeps}
      events={events}
      eventsDeps={eventsDeps}
    />
  );
};
