import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import * as AppActions from '@app/app-actions';
import { CoreState, INIT_STATE, STORE_KEY } from '../interfaces';

const coreSlice = createSlice({
  name: STORE_KEY,
  initialState: INIT_STATE,
  reducers: {
    popupShown: (state: CoreState) => {
      state.isPopupShown = true;
      state.badgeCount = 0;
      state.lastInteractionTime = Date.now();
    },
    popupToggled: (state: CoreState, action: PayloadAction<undefined | boolean>) => {
      state.isPopupShown = action.payload !== undefined ? action.payload : !state.isPopupShown;
      if (current(state).isPopupShown) state.badgeCount = 0;
      state.lastInteractionTime = Date.now();
    },
    popupHovered: (state: CoreState, action: PayloadAction<boolean>) => {
      state.isPopupHovered = action.payload;
      state.lastInteractionTime = Date.now();
    },
    popupFocused: (state: CoreState, action: PayloadAction<boolean>) => {
      state.isPopupFocused = action.payload;
      state.lastInteractionTime = Date.now();
      if (action.payload) {
        state.badgeCount = 0;
      }
    },
    initBadgeCount: (state: CoreState) => {
      state.badgeCount = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(AppActions.remoteConfigFetched, (state, action) => {
      const { isWithinOfficeHours, shouldPopupOnNewMessage } = action.payload;

      state.isLauncherShown = isWithinOfficeHours;
      state.shouldPopupOnNewMessage = shouldPopupOnNewMessage;
    });
    builder.addCase(AppActions.receiveMessageReplied, state => {
      state.isPopupShown = state.isPopupShown || state.shouldPopupOnNewMessage;
      state.badgeCount++;
    });
  },
});

export { coreSlice };
export default coreSlice.reducer;
export const showPopup = coreSlice.actions.popupShown;
export const togglePopup = coreSlice.actions.popupToggled;
export const handlePopupHover = coreSlice.actions.popupHovered;
export const handlePopupFocus = coreSlice.actions.popupFocused;
export const initBadgeCount = coreSlice.actions.initBadgeCount;
