import { ConversationState } from './redux';

export const STORE_KEY = 'conversation';

export const INIT_STATE: ConversationState = {
  conversation: {},
  messages: [],
  showAgentReplyingDots: false,
};

export const WHITE_LIST_MESSAGE_TYPE = ['received', 'sent'];

export enum ContentPaylaodType {
  LINK = 'link',
  UPLOAD_LINK = 'upload_link',
  DOWNLAD_FILE = 'download_file',
}
