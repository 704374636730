export enum TimeUnits {
  SECONDS = 's',
  MINUTES = 'm',
  HOURS = 'H',
  DAYS = 'd',
}

export const TIME_UNITS_IN_MILLISECONDS = {
  [TimeUnits.SECONDS]: 1000,
  [TimeUnits.MINUTES]: 60 * 1000,
  [TimeUnits.HOURS]: 60 * 60 * 1000,
  [TimeUnits.DAYS]: 24 * 60 * 60 * 1000,
};
