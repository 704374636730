import { ContentMeta } from './content-meta';

export enum EMessageType {
  RECEIVED = 'received',
  SENT = 'sent',
}

export interface MessageData {
  id: string;
  conversation_id: string;
  content: string;
  // intent: string;
  // tags: IMessageTagData[];
  type: EMessageType;
  created_at: string;
  updated_at: string;
  content_meta: null | ContentMeta;
}
