import { STORE_KEY as CORE_STORE_KEY } from '@onr/core';
import { FETCHING_STATUS, STORE_KEY as SETTINGS_STORE_KEY } from '@onr/settings';
import { AutoPopupSettings } from './interfaces';
import { State } from './store';
import { STORE_KEY as APP_STORE_KEY } from './slice';

export const selectAutoPopupConditions = (
  state: State
): {
  isLauncherShown: boolean;
  isPopupShown: boolean;
  isPopupHovered: boolean;
  isPopupFocused: boolean;
  lastInteractionTime: number | undefined;
  autoPopup: AutoPopupSettings;
} => {
  const {
    isLauncherShown,
    isPopupShown,
    isPopupHovered,
    isPopupFocused,
    lastInteractionTime,
  } = state[CORE_STORE_KEY];
  const { auto_popup: autoPopup } = state[SETTINGS_STORE_KEY];
  const { auto_popup: staticAutoPopup } = state[APP_STORE_KEY].settings || {};

  return {
    // static settings to override remote settings
    autoPopup: {
      ...autoPopup,
      ...staticAutoPopup,
    },
    isLauncherShown,
    isPopupShown,
    isPopupHovered,
    isPopupFocused,
    lastInteractionTime,
  };
};

export const selectFetchingSettingStatus = (state: State): boolean => {
  return state[SETTINGS_STORE_KEY][FETCHING_STATUS];
};
