import { Http, logCatch } from '@onr/core';
import { SettingsData } from '../interfaces';

export const SettingsService = {
  getSettings: async (campaign_id: number) => {
    try {
      // hard-code group_id 5 be settings for webchat
      const params = {
        group_id: 5,
      };
      const res = await Http.get<SettingsData[]>(`/api/campaigns/${campaign_id}/settings`, params);

      return res;
    } catch (e) {
      throw logCatch(e, 'settings', 'getSettings');
    }
  },
} as const;
