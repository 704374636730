import { ConversationState, State, STORE_KEY } from '../interfaces';

export const selectConversation = (state: State): ConversationState['conversation'] =>
  state[STORE_KEY].conversation;

export const selectConversationId = (state: State): ConversationState['conversation']['id'] =>
  state[STORE_KEY].conversation.id;

export const selectMessages = (state: State): ConversationState['messages'] =>
  state[STORE_KEY].messages;

export const selectAgentReplyingDots = (state: State): ConversationState['showAgentReplyingDots'] =>
  state[STORE_KEY].showAgentReplyingDots;
