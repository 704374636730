import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

const baseConfig = {
  baseURL: `${process.env.API_URL}`,
  validateStatus: (status: number) => status >= 200 && status <= 302,
};

export const instance = axios.create(baseConfig);

// TODO: correctly set header before submitting any request
export const setAuthorizationHeader = (value: string): void => {
  instance.defaults.headers.common['Authorization'] = value;
};

const cancelTokens: Record<string, CancelTokenSource> = {};

export const Http = {
  request: async <A, B = Record<string, unknown>>({
    method,
    url,
    params = {},
    data = {},
    headers = {},
    ...options
  }: AxiosRequestConfig): Promise<{ data: A } & B> => {
    if (!url) throw new Error('url cannot be empty');

    if (method === 'GET') {
      if (cancelTokens[url]) {
        //cancel previous request
        cancelTokens[url].cancel('Operation canceled');
      }

      //store cancel token
      cancelTokens[url] = axios.CancelToken.source();
    }

    try {
      const res = await instance({
        url,
        method,
        headers,
        params,
        data,
        cancelToken: cancelTokens[url]?.token,
        ...options,
      });

      delete cancelTokens[url];

      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw error;
      } else {
        delete cancelTokens[url];
        throw error;
      }
    }
  },

  get: async <A, B = Record<string, unknown>>(
    url: string,
    params = {},
    options = {}
  ): Promise<{ data: A } & B> => {
    return Http.request({ method: 'GET', url, params, ...options });
  },

  post: async <A, B = Record<string, unknown>>(
    url: string,
    data = {},
    options = {}
  ): Promise<{ data: A } & B> => {
    return Http.request({ method: 'POST', url, data, ...options });
  },

  patch: async <A, B = Record<string, unknown>>(
    url: string,
    data = {},
    options = {}
  ): Promise<{ data: A } & B> => {
    return Http.request({ method: 'PATCH', url, data, ...options });
  },

  put: async <A, B = Record<string, unknown>>(
    url: string,
    data = {},
    options = {}
  ): Promise<{ data: A } & B> => {
    return Http.request({ method: 'PUT', url, data, ...options });
  },

  delete: async <A, B = Record<string, unknown>>(
    url: string,
    options = {}
  ): Promise<{ data: A } & B> => {
    return Http.request({ method: 'DELETE', url, ...options });
  },
};
